import React, { useState } from "react";
import classes from "./Contact.module.css";
import LogoSVG from "../../Icons/LogoSVG";
import MailSVG from "../../Icons/MailSVG";
import LocatSVG from "../../Icons/LocatSVG";
import PhoneSVG from "../../Icons/PhoneSVG";
import LinkedInSVG from "../../Icons/LinkedInSVG";
import Inform from "./InfoForm/InForm";
import classNames from "classnames";
import emailjs from "emailjs-com";
import apiKeys from "../../apikeys";
import fb from "../../Icons/facebook.png";

const Contact = () => {
  const [form, setForm] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);

  const onSubmitSubscriber = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs
      .sendForm(
        "service_oq0b1lr",
        apiKeys.TEMPLATE_ID2,
        e.target,
        apiKeys.USER_ID
      )
      .then(
        (result) => {
          alert("Message Sent, I'll get back to you shortly", result.text);
        },
        (error) => {
          alert("An error occured, Plese try again", error.text);
        }
      );
  };

  return (
    <div id="ContactUs" className={classes.Contact}>
      <div className={classNames(classes.top, form && classes.top_form)}>
        <p className={classes.contacttext1}>
          Ready To Unlock Your Business Potentials?
        </p>
        <div className={classes.contacttext2} onClick={() => setForm(!form)}>
          Get in Touch
        </div>
        {form && (
          <Inform
            formSubmit={formSubmit}
            setFormSubmit={setFormSubmit}
            setForm={setForm}
          />
        )}
        {/* {formSubmit && <div>SUBMITTED!!</div>} */}
      </div>

      <div className={classes.bottom}>
        <div className={classes.bottomContainer}>
          <div className={classes.left}>
            <LogoSVG className={classes.logoSVG} />
            <div className={classes.bottomLT}>
              <LocatSVG className={classes.ContactSVG} />
              <a href="https://goo.gl/maps/rXtyzeN5F9dV2ate9">
                22b Sikiru Alade Oloko Cres, Lekki Phase I, Lagos
              </a>
             
              <div></div>
            </div>
            <div className={classes.bottomLT}>
              <LocatSVG className={classes.ContactSVG} />
             
              <a href="https://goo.gl/maps/rXtyzeN5F9dV2ate9">
              4559 Mountain Laurel Dr, Grande Praire, TX 75052, USA
              </a>
              <div></div>
            </div>
            <div className={classes.bottomLT}>
              <PhoneSVG className={classes.ContactSVG} />
              <a href="tel:+234-802-686-5279">+234-802-686-5279</a>
            </div>
            <div className={classes.bottomLT}>
              <MailSVG className={classes.ContactSVG} />

              <a href="https://ng.linkedin.com/company/lytical-technology">
                Info@lyticaltechnology.com
              </a>
            </div>

            <div className={classes.flex}>
              <img
                className={classes.socialIcon}
                src={fb}
                alt="Facebook logo "
              />
              <a
                className={classes.socialFont}
                href="https://www.facebook.com/profile.php?id=61553475304900"
                target="blank"
              >
                Lytical Technology
              </a>
            </div>
          </div>
          <div className={classes.right}>
            <p className={classes.contacttext3}>Subscribe to our Service</p>
            <div>
              <form onSubmit={onSubmitSubscriber}>
                <input
                  className={classes.contactInput}
                  placeholder="Email Address"
                  name="subEmail"
                />
                <input
                  type="submit"
                  value="Submit"
                  className={classes.contactButton}
                />
              </form>
            </div>
          </div>
        </div>

        <div className={classes.HLine}></div>
        <div className={classes.contacttext4}>
          Copyright © {new Date().getFullYear()} Lytical Technology. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Contact;

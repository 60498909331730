import classes from "./App.module.css";
import Navigation from "./components/Navigation/Navigation";
import AboutUs from "./components/Pages/AboutUs/AboutUs";
import Clients from "./components/Pages/Clients/Clients";
import Contact from "./components/Pages/ContactUs/Contact";
import Slideshow from "./components/Pages/Home/Slideshow";
import Services from "./components/Pages/Services/Services";
import Testim from "./components/Pages/Testimonials/Testim";

function App() {
  return (
    <div className={classes.App}>
      <div className={classes.page}>
        <Navigation />
        <Slideshow />
        <AboutUs />
        <Services />

        <Testim />
        <Clients />
        <Contact />
      </div>
    </div>
  );
}

export default App;

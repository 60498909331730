import React from "react";
import classes from "./Clients.module.css";
import kwo from "../../images/kwo.jpg";
import flash from "../../images/flash.jpg";
import appt from "../../images/appt.jpg";
import lyt from "../../images/lyt.jpg";

const Clients = () => {
  return (
    <div id="Clients" className={classes.Clients}>
      <div className={classes.clientText1}>Our Top Clients</div>
      <div className={classes.clientImages}>
        <img src={lyt} alt="lytical" className={classes.imag} />
        <img src={flash} alt="flash" className={classes.imag} />
        <img src={appt} alt="appt" className={classes.imag} />
        <img src={kwo} alt="kwo" className={classes.imag} />
      </div>
    </div>
  );
};

export default Clients;

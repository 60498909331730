import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="48"
      height="44"
      viewBox="0 0 48 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.19196 13.5849C6.58632 9.87109 7.46681 5.93313 8.29189 2.11056C8.46676 1.28699 7.95571 0.464601 7.14739 0.335361C6.34359 0.146611 5.54811 0.677697 5.37834 1.4418C4.5819 5.14703 3.74199 8.96797 2.40278 12.5068C2.11349 13.2607 2.50107 14.1329 3.2703 14.4384C4.03893 14.744 4.90208 14.3387 5.19196 13.5849Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3427 26.4185C26.1179 20.8302 32.155 14.4 39.0877 8.94506C39.733 8.46134 39.8467 7.51273 39.3382 6.87013C38.8291 6.22748 37.8923 6.08746 37.242 6.63064C30.2908 12.084 24.2359 18.5127 17.4423 24.0994C16.8062 24.6438 16.7162 25.5942 17.2408 26.2382C17.7699 26.8226 18.7066 26.9629 19.3427 26.4185Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.3973 39.9572C38.8049 39.8287 35.2125 39.6999 31.6195 39.5713C30.8001 39.5011 30.1062 40.1607 30.0746 40.9965C30.0437 41.8324 30.6901 42.4865 31.5101 42.5568C35.1085 42.6858 38.7062 42.8151 42.304 42.9441C43.1284 42.9549 43.8182 42.2952 43.845 41.4589C43.8667 40.6821 43.2212 39.968 42.3973 39.9572Z"
        fill="white"
        fill-opacity="0.95"
      />
    </svg>
  );
}

export default SvgAddButton;

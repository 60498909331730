import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="60"
      height="51"
      viewBox="0 0 60 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.5362 5.64776C46.4469 5.79683 48.7604 7.26793 51.074 8.91979C58.6615 14.3444 61.1992 22.7609 55.9999 31.0316C47.9148 43.8574 29.0826 52.2517 14.4797 48.3777C11.2456 47.5239 6.86724 46.7714 3.65808 42.602C0.672808 38.7253 0.797194 34.1166 2.16544 29.8499C3.55857 25.4753 6.24531 21.449 8.38475 18.8609C17.7386 7.56903 36.4711 -2.69374 53.4125 2.71671C53.7608 2.82096 54.1091 2.64168 54.2087 2.31631C54.3082 1.99094 54.1339 1.64205 53.8105 1.5378C36.3715 -4.03039 17.0419 6.45277 7.41444 18.0737C4.75258 21.3009 1.26996 26.6881 0.274869 32.262C-0.396816 36.0716 0.0757544 39.9659 2.66299 43.3535C6.07117 47.7948 10.7233 48.6647 14.1563 49.574C29.2319 53.5752 48.7106 44.9356 57.0445 31.689C62.6419 22.7995 59.9552 13.7446 51.7955 7.91396C49.3078 6.1217 46.7702 4.57285 43.6108 4.41141C43.2625 4.39383 42.9641 4.65655 42.9641 4.99777C42.9392 5.33898 43.2128 5.63018 43.5362 5.64776Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgAddButton;

import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="40"
      height="38"
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8037 14.0282C14.0906 10.976 11.082 7.34124 8.82111 3.10645C8.36893 2.26297 7.31679 1.94123 6.46462 2.3934C5.62114 2.84558 5.29934 3.89777 5.75151 4.74994C8.23847 9.38473 11.5255 13.3673 15.5951 16.7152C16.3342 17.3239 17.4385 17.2195 18.0472 16.4804C18.6559 15.7325 18.5428 14.6369 17.8037 14.0282Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8237 2.14127C16.015 5.65431 16.189 9.16735 16.4064 12.6804C16.4672 13.6369 17.2933 14.3673 18.2498 14.3065C19.2064 14.2456 19.9368 13.4195 19.8759 12.463C19.6585 8.95866 19.4846 5.45433 19.2933 1.94129C19.2411 0.987372 18.415 0.252585 17.4585 0.306498C16.502 0.360411 15.7629 1.18214 15.8237 2.14127Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.7333 2.85437C26.5072 6.21089 23.9856 9.3065 21.4465 12.4369C20.8378 13.1848 20.9507 14.2805 21.6986 14.8805C22.4464 15.4892 23.5421 15.3761 24.1421 14.6283C26.7508 11.4196 29.3421 8.22825 31.6377 4.77607C32.1682 3.97607 31.942 2.89785 31.142 2.36741C30.3507 1.83698 29.2638 2.05437 28.7333 2.85437Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.3564 15.3326C31.3216 15.6022 24.9129 15.6109 18.8782 15.2282C17.9216 15.1761 17.0955 15.8978 17.0347 16.863C16.9738 17.8196 17.7042 18.6456 18.6608 18.7065C24.8173 19.0891 31.3565 19.0804 37.513 18.8108C38.4695 18.7674 39.2173 17.95 39.1738 16.9935C39.1303 16.037 38.3129 15.2891 37.3564 15.3326Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5905 16.4368C19.3122 20.9933 23.1645 25.463 26.6166 30.2282C27.1818 31.0021 28.2688 31.1759 29.0515 30.6107C29.8254 30.0455 29.9992 28.9585 29.434 28.1846C25.9384 23.3672 22.0427 18.8455 18.2775 14.2368C17.6688 13.4977 16.5731 13.3847 15.834 13.9934C15.0862 14.602 14.9818 15.6977 15.5905 16.4368Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7176 17.759C17.6915 22.5068 17.9611 27.0459 18.0306 31.8546C18.0393 32.8112 18.8306 33.5764 19.7872 33.5677C20.7524 33.5503 21.5176 32.7676 21.5002 31.8024C21.4306 26.7676 21.1437 22.0285 20.1263 17.0546C19.9263 16.1155 19.0133 15.5068 18.0654 15.7068C17.1263 15.8981 16.5176 16.8198 16.7176 17.759Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8746 17.3765C14.0572 23.0113 12.605 29.2722 9.5876 34.7852C9.12673 35.62 9.43971 36.6809 10.2832 37.1417C11.1267 37.6026 12.1789 37.2895 12.6398 36.4547C15.6746 30.9069 17.1441 24.6113 19.9876 18.933C20.4136 18.0722 20.0658 17.0287 19.2136 16.5939C18.3528 16.1678 17.3093 16.5157 16.8746 17.3765Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4251 16.0978C12.8164 17.0891 7.21637 17.5413 1.57289 18.2108C0.616372 18.3239 -0.061889 19.1848 0.0511545 20.1413C0.164198 21.0978 1.02515 21.7761 1.98167 21.663C7.68602 20.9935 13.3556 20.5239 19.0338 19.5239C19.973 19.35 20.6077 18.4456 20.4425 17.5065C20.2686 16.5587 19.3729 15.9239 18.4251 16.0978Z"
        fill="white"
        fill-opacity="0.95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.3278 13.4548C21.1712 13.4026 20.0756 13.3243 18.9451 13.1591C17.9886 13.02 17.1103 13.6809 16.9712 14.6287C16.8321 15.5765 17.4929 16.4635 18.4407 16.6026C19.6929 16.7852 20.9016 16.8722 22.1712 16.9244C23.1277 16.9678 23.9451 16.2287 23.9799 15.2635C24.0233 14.3069 23.2843 13.4983 22.3278 13.4548Z"
        fill="white"
        fill-opacity="0.95"
      />
    </svg>
  );
}

export default SvgAddButton;

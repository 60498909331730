import React, { useState } from "react";
import classes from "./Navigation.module.css";
import LogoSVG from "../Icons/LogoSVG";
import MenuIconSVG from "../Icons/MenuIconSVG";
import SideDrawer from "../SideDrawer/SideDrawer";

const Navigation = () => {
  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const SideDrawerToggle = () => {
    setShowSideDrawer(!showSideDrawer);
  };

  return (
    <nav>
      <div onClick={SideDrawerToggle}>
        <MenuIconSVG className={classes.MenuIconSVG} />
      </div>
      <SideDrawer backDrop={showSideDrawer} backDropClick={SideDrawerToggle} />
      <div className={classes.LogoSVG}>
        <LogoSVG />
      </div>
      <div className={classes.Navigation}>
        <a href="#Home">Home</a>
        <a href="#AboutUs">About Us</a>
        <a href="#Services">Services</a>
        <a href="#Clients">Clients</a>
      </div>
      <div className={classes.text2}>
        <a href="#ContactUs">Contact Us</a>
      </div>

   
      
    </nav>
  );
};

export default Navigation;

import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="50" height="50" rx="5" fill="#18947B" />
      <g filter="url(#filter0_d_108_100)">
        <rect
          x="13.75"
          y="15.75"
          width="22.5"
          height="18.5"
          rx="1.25"
          stroke="white"
          stroke-width="1.5"
          shape-rendering="crispEdges"
        />
      </g>
      <rect x="15.5" y="27" width="4" height="6" rx="1" fill="white" />
      <rect x="20.5" y="21" width="4" height="12" rx="1" fill="white" />
      <rect x="25.5" y="24" width="4" height="9" rx="1" fill="white" />
      <rect x="30.5" y="18" width="4" height="15" rx="1" fill="white" />
      <defs>
        <filter
          id="filter0_d_108_100"
          x="9"
          y="15"
          width="32"
          height="28"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_108_100"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_108_100"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgAddButton;

import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8189 0H1.14245C0.840182 0 0.550294 0.126428 0.336558 0.351472C0.122822 0.576515 0.00274658 0.88174 0.00274658 1.2V10.8C0.00274658 11.1183 0.122822 11.4235 0.336558 11.6485C0.550294 11.8736 0.840182 12 1.14245 12H14.8189C15.1212 12 15.411 11.8736 15.6248 11.6485C15.8385 11.4235 15.9586 11.1183 15.9586 10.8V1.2C15.9586 0.88174 15.8385 0.576515 15.6248 0.351472C15.411 0.126428 15.1212 0 14.8189 0ZM13.5652 1.2L7.98067 5.268L2.39612 1.2H13.5652ZM1.14245 10.8V1.746L7.65585 6.492C7.75124 6.56168 7.86457 6.59901 7.98067 6.59901C8.09676 6.59901 8.21009 6.56168 8.30548 6.492L14.8189 1.746V10.8H1.14245Z"
        fill="#777777"
      />
    </svg>
  );
}

export default SvgAddButton;

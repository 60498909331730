import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.4275 0.345188C42.3939 11.5623 40.5913 27.3001 42.6016 43.534C45.6081 67.7893 57.1086 93.1428 72.0812 106.053C72.393 106.316 72.8607 106.28 73.1229 105.975C73.3921 105.664 73.3567 105.203 73.0449 104.934C58.3061 92.2216 47.0253 67.2366 44.0705 43.3498C42.1027 27.4844 43.8012 12.0937 50.6817 1.13172C50.9013 0.784507 50.7951 0.331047 50.4478 0.111383C50.1077 -0.101196 49.6471 -0.00202359 49.4275 0.345188Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.7955 125.782C76.5049 117.86 70.0283 110.065 58.7191 105.495C58.3365 105.346 57.9042 105.523 57.7554 105.906C57.5996 106.281 57.7838 106.714 58.1593 106.862C70.6023 111.894 77.0789 120.921 86.7512 129.623C87.2756 130.09 87.7149 129.8 87.8141 129.722C87.9629 129.608 88.1613 129.382 88.0904 128.985C88.0763 128.907 88.0266 128.758 87.9274 128.56C87.729 128.184 87.2614 127.454 87.0842 127.107C86.5811 126.101 86.1064 125.088 85.6033 124.081C84.1648 121.205 82.7618 118.321 81.5713 115.337C77.1001 104.184 73.3729 95.0928 77.993 83.5427C78.1489 83.16 77.9646 82.7348 77.5819 82.5789C77.2064 82.4301 76.7741 82.6144 76.6253 82.9899C71.8423 94.9369 75.5766 104.354 80.1967 115.89C81.4084 118.909 82.8256 121.835 84.2782 124.74C84.4554 125.088 84.6254 125.435 84.7955 125.782Z"
        fill="#333333"
      />
    </svg>
  );
}

export default SvgAddButton;

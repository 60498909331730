import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="50" height="50" rx="5" fill="#18947B" />
      <rect
        x="13.75"
        y="14.75"
        width="22.5"
        height="18.5"
        rx="1.25"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M13 29H37V32C37 33.1046 36.1046 34 35 34H15C13.8954 34 13 33.1046 13 32V29Z"
        fill="white"
      />
      <rect x="22" y="34" width="6" height="2" fill="white" />
    </svg>
  );
}

export default SvgAddButton;

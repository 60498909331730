// import axios from "axios";
import React from "react";
import classes from "./InForm.module.css";
import emailjs from "emailjs-com";
import apiKeys from "../../../apikeys";

const InForm = ({ formSubmit, setFormSubmit, setForm }) => {
  const onSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs
      .sendForm(
        "service_oq0b1lr",
        apiKeys.TEMPLATE_ID1,
        e.target,
        apiKeys.USER_ID
      )
      .then(
        (result) => {
          alert("Message Sent, I'll get back to you shortly", result.text);
          setForm(false);
        },
        (error) => {
          alert("An error occured, Plese try again", error.text);
        }
      );
  };

  // const resetForm = () => {
  //   setName("");
  //   setEmail("");
  //   setSubject("");
  // };

  return (
    <div className={classes.InForm}>
      <div className={classes.formField}>
        <form
          // method="POST"
          // enctype="multipart/form-data"
          // name="EmailForm"
          onSubmit={onSubmit}
        >
          <br></br>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            className={classes.formInput1}
            required
            // value={name}
            // onChange={(e) => setName(e.target.value)}
          />
          <br></br>
          <br></br>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Email"
            className={classes.formInput2}
            required
            // value={email}
            // onChange={(e) => setEmail(e.target.value)}
          />
          <br></br>
          <br></br>
          <textarea
            id="subject"
            name="subject"
            rows="4"
            cols="50"
            placeholder="Subject"
            className={classes.formInput3}
            // value={subject}
            // onChange={(e) => setSubject(e.target.value)}
          ></textarea>
          <br></br>
          <input
            type="submit"
            value="Submit"
            className={classes.submitButton}
            // onSubmit={() => {
            //   setFormSubmit(true);
            //   setForm(false);
            // }}
          />
        </form>
      </div>
    </div>
  );
};

export default InForm;

import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="50" height="50" rx="10" fill="#18947B" />
      <g clip-path="url(#clip0_108_90)">
        <path
          d="M27.0737 34.2226L28.9825 34.324V37.0008H21.0176V34.4565L22.1225 34.3568C22.7244 34.2881 23.0924 34.0885 23.0924 33.2854V23.9817C23.0924 23.2425 22.8912 23.0086 22.255 23.0086L21.0846 22.9431V20.1658H27.0737V34.2226ZM24.7322 13C26.2051 13 27.2764 14.1073 27.2764 15.5115C27.2764 16.919 26.2051 17.956 24.6986 17.956C23.1603 17.956 22.1544 16.9174 22.1544 15.5115C22.1544 14.1065 23.1603 13 24.7322 13Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_108_90">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAddButton;

import React, { useEffect, useRef, useState } from "react";
import "./Slideshow.css";
import slide1 from "../../images/img3.png";
import slide2 from "../../images/it.png";
import slide3 from "../../images/mobile.png";

const Slideshow = () => {
  const colors = ["#0088FE", "#00C49F", "#FFBB28"];
  const slidesData = [
    {
      text1: "Data Management & ",
      text3: "Real-time Analytics",
      text2: "Make better and timely decision with your data",
      image: slide1,
    },
    {
      text1: "IT Consulting & ",
      text2: "Get professional advice on your IT services",
      text3: "Advisory",

      image: slide2,
    },
    {
      text1: "Mobile & Desktop",
      text3: "App Development",
      text2: "We deliver technology that solves complex problems",
      image: slide3,
    },
  ];
  const delay = 3000;
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div id="Home" className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {slidesData.map((slides, index) => (
          <div className="slide" key={index}>
            <div className="Home">
              <div className="left">
                <p className="hometext1">
                  {slides.text1}
                  <br></br>
                  {slides.text3}
                </p>
                <p className="hometext2">{slides.text2}</p>
              </div>
              <div className="right">
                <img
                  src={slides.image}
                  alt="What we do"
                  className="sliderImage"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="slideshowDots">
        {slidesData.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;

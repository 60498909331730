import React from "react";
import classes from "./Testim.module.css";
import testim1 from "../../images/testim1.png";
import testim2 from "../../images/testim2.png";
import TestimSVG2 from "../../Icons/TestimSVG2";
import TestimSVG1 from "../../Icons/TestimSVG1";
import TestimSVG3 from "../../Icons/TestimSVG3";
import TestimSVG4 from "../../Icons/TestimSVG4";
import TestimSVG5 from "../../Icons/TestimSVG5";
import TestimSVG6 from "../../Icons/TestimSVG6";

const Testim = () => {
  return (
    <div id="Testim" className={classes.Testim}>
      <p className={classes.testimtext1}>A Company With A Mission And Vision</p>
      <p className={classes.testimtext2}>
        We are a company that aims to transform the digital space with cost
        effective data solutions
      </p>
      <div className={classes.boxCont}>
        <div className={classes.box1}>
          <div className={classes.left}>
            <div>
              <p className={classes.testimtext3}>Our Mission statement</p>
              <p className={classes.testimtext4}>
                To deliver the highest quality and cost effective data solutions
                that will help businesses make proactive data-driven decisions
                to improve scalability and profitability.
              </p>
              <TestimSVG2 className={classes.TestimSVG2} />
            </div>
          </div>
          <div className={classes.right}>
            <TestimSVG1 className={classes.TestimSVG1} />
            <img src={testim1} alt="people" className={classes.img1} />
            <TestimSVG3 className={classes.TestimSVG3} />
          </div>
        </div>
        <div className={classes.box2}>
          <TestimSVG4 className={classes.TestimSVG4} />
          <div className={classes.left2}>
            <img src={testim2} alt="people" className={classes.img2} />
          </div>
          <div className={classes.right2}>
            <TestimSVG5 className={classes.TestimSVG5} />
            <p className={classes.testimtext5}>Our Vision statement</p>
            <p className={classes.testimtext6}>
              Our vision is to be the most reliable and innovative technology
              company in the world. We want to transform how organizations
              interact, use and gain insight from their data.
            </p>
            <TestimSVG6 className={classes.TestimSVG6} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testim;

import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="50" height="50" rx="5" fill="#18947B" />
      <path
        d="M16.1204 23.4183C10.1745 27.3581 11.0979 31.2388 16.1204 34H34.3299C38.8344 31.1505 39.5975 27.3587 34.3299 23.4183C33.8219 20.4774 32.9956 18.6898 28.6324 18.9901C23.2959 13.5089 17.4207 15.9001 16.1204 23.4183Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgAddButton;

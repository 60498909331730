import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.7031 0C2.11677 0 0 2.11672 0 4.7031V19.2977C0 21.884 2.11672 24 4.7031 24H19.2977C21.884 24 24 21.884 24 19.2977V4.7031C24 2.11677 21.884 0 19.2977 0H4.7031ZM5.886 3.96048C7.1261 3.96048 7.88994 4.77458 7.91352 5.84471C7.91352 6.8912 7.12605 7.72819 5.86202 7.72819H5.83875C4.62226 7.72819 3.83599 6.89125 3.83599 5.84471C3.83599 4.77461 4.64605 3.96048 5.88598 3.96048H5.886ZM16.5726 8.96211C18.9575 8.96211 20.7454 10.5209 20.7454 13.8707V20.1242H17.1209V14.29C17.1209 12.8239 16.5964 11.8237 15.2847 11.8237C14.2834 11.8237 13.6865 12.4978 13.4245 13.1491C13.3287 13.3821 13.3052 13.7075 13.3052 14.0335V20.1242H9.68074C9.68074 20.1242 9.72831 10.2412 9.68074 9.21787H13.3059V10.7623C13.7876 10.0192 14.6491 8.96208 16.5726 8.96208V8.96211ZM4.04978 9.21866H7.67423V20.1243H4.04978V9.21866Z"
        fill="#444444"
      />
    </svg>
  );
}

export default SvgAddButton;

import React from "react";
import LogoSVG from "../Icons/LogoSVG";
// import Navigation from "../Navigation/Navigation";
import classes from "./SideDrawer.module.css";
import BackDrop from "../BackDrop/BackDrop";

const sideDrawer = (props) => {
  let classesAttached = [classes.SideDrawer, classes.Close];

  if (props.backDrop) {
    classesAttached = [classes.SideDrawer, classes.Open];
  }

  return (
    <div onClick={props.backDropClick}>
      <div className={classes.BackDrop}>
        <BackDrop show={props.backDrop} clickedBackDrop={props.backDropClick} />
      </div>
      <div className={classesAttached.join(" ")}>
        <div>
          <LogoSVG className={classes.Logo} />
        </div>
        <div className={classes.Navigation}>
          <a href="#Home">Home</a>
          <a href="#AboutUs">About Us</a>
          <a href="#Services">Services</a>
          <a href="#Clients">Clients</a>
        </div>
        <div className={classes.text2}>
          <a href="#ContactUs">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default sideDrawer;

import React from "react";
import classes from "./AboutUs.module.css";
import aboutImg1 from "../../images/aboutImg1.jpg";
import aboutImg2 from "../../images/aboutImg2.jpg";

const AboutUs = () => {
  return (
    <div id="AboutUs" className={classes.AboutUs}>
      <p className={classes.abouttext1}>Who We Are</p>
      <div className={classes.AboutUsText}>
        <div className={classes.left}>
          <img alt="about" src={aboutImg1} className={classes.aboutImg1} />
        </div>
        <div className={classes.right}>
          <img src={aboutImg2} alt="divider" className={classes.aboutImg2} />
          <div>
            <p className={classes.abouttext2}>
              A Data Management & Software Company
            </p>
            <p className={classes.abouttext3}>
              We are a data management and software company that provide
              services which enables clients maximize the use of their
              internally generated data and use that to its full potential. Our
              services and solutions enables the prevention and resolution of
              organizational data quality problems,using industry-leading
              methodologies and technology. Our expert engineers and analysts
              are always ready to provide on-the-ground help for problems that
              require more nuanced approaches.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.24 1C14.44 1 17 3.56 17 6.76M11.24 4.2C12.52 4.2 13.8 5.48 13.8 6.76M1 1C1 11.88 6.12 17 17 17V11.88L12.52 10.6L11.24 12.52C8.68 12.52 5.48 9.32 5.48 6.76L7.4 5.48L6.12 1H1Z"
        stroke="#777777"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SvgAddButton;

import React from "react";
import classes from "./Services.module.css";
import ServSVG1 from "../../Icons/servSVG1";
import ServSVG2 from "../../Icons/servSVG2";
import ServSVG3 from "../../Icons/servSVG3";
import ServSVG4 from "../../Icons/servSVG4";
import ServSVG5 from "../../Icons/servSVG5";
import ServSVG6 from "../../Icons/servSVG6";

const Services = () => {
  return (
    <div id="Services" className={classes.Services}>
      <p className={classes.servicestext1}>
        Here’s How We Can Help Your Business
      </p>
      <div className={classes.servicesBox}>
        <div className={classes.box}>
          <ServSVG1 className={classes.servSVG} />
          <div className={classes.boxTitle}>IT Consulting & Advisory</div>
          <div className={classes.boxText}>
            Our IT and Advisory team designs and executes industry-relevant
            solutions that allow organizations to realize exceptional business
            value from technology.
          </div>
        </div>
        <div className={classes.box}>
          <ServSVG2 className={classes.servSVG} />
          <div className={classes.boxTitle}>Data Management & Analytics</div>
          <div className={classes.boxText}>
            We help businesses realize the true potential of their data through
            our data management & analytics services, helping them make crucial
            business decisions.
          </div>
        </div>
        <div className={classes.box}>
          <ServSVG3 className={classes.servSVG} />
          <div className={classes.boxTitle}>Mobile App. Development</div>
          <div className={classes.boxText}>
            Our mobile app development team will help you create practical and
            seamless experiences on any device and get the best of mobile
            technology for your business.
          </div>
        </div>
        <div className={classes.box}>
          <ServSVG4 className={classes.servSVG} />
          <div className={classes.boxTitle}>Desktop Software Development</div>
          <div className={classes.boxText}>
            We create custom desktop applications for various industries. Our
            apps bring your business the ability to create customized features
            and functionality to tackle your unique business challenges.
          </div>
        </div>
        <div className={classes.box2}></div>
        <div className={classes.box}>
          <ServSVG5 className={classes.servSVG} />
          <div className={classes.boxTitle}>Web-Based Software Development</div>
          <div className={classes.boxText}>
            We leverage latest technologies to help plan and build powerful,
            intuitive, user-friendly and secure custom web applications and APIs
            tailored to specific business needs..
          </div>
        </div>
        <div className={classes.box}>
          <ServSVG6 className={classes.servSVG} />
          <div className={classes.boxTitle}>Web Hosting and Cloud Services</div>
          <div className={classes.boxText}>
            Web hosting and cloud computing can be tedious. It becomes easier
            though with our quality services. Our reliable web hosting makes
            managing your website or app easy for you.
          </div>
        </div>
        <div className={classes.box3}></div>
      </div>
    </div>
  );
};

export default Services;

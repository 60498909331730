import * as React from "react";

function SvgAddButton(props) {
  return (
    <svg
      width="80"
      height="69"
      viewBox="0 0 80 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.1116 28.5826C48.0933 27.2506 47.9188 25.907 47.5697 24.5543C46.3456 19.8073 40.8843 16.7597 35.0716 16.0431C29.2612 15.3266 23.2188 16.9572 20.8901 21.0864C19.558 23.4473 19.3743 25.4822 19.9186 27.1977C20.4606 28.9041 21.7466 30.3188 23.492 31.4212C28.3585 34.4917 36.8813 35.1187 40.7695 33.8349C42.5677 33.2401 44.3245 32.5236 46.0355 31.7037C45.0572 37.0708 41.4124 42.1555 36.5482 46.7372C25.977 56.6953 9.57238 64.2534 0.638597 67.0323C0.158608 67.1815 -0.107821 67.6914 0.0414584 68.1714C0.190737 68.6514 0.700574 68.9201 1.18056 68.7708C10.2912 65.9368 27.0151 58.2179 37.7953 48.0624C43.3646 42.8169 47.3331 36.9124 48.0106 30.7046C60.6006 23.9779 70.9399 11.7738 79.7795 1.50337C80.1079 1.12443 80.0643 0.547964 79.6831 0.21955C79.3018 -0.106568 78.7276 -0.0652466 78.3992 0.315989C69.9202 10.1661 60.0792 21.8834 48.1116 28.5826ZM46.2813 29.5586C46.3502 28.0613 46.2032 26.5409 45.8058 25.0068C44.7471 20.8981 39.8806 18.4684 34.8488 17.8483C31.7644 17.4693 28.5929 17.7817 26.1056 18.8932C24.5417 19.5914 23.2532 20.6042 22.4769 21.9822C21.4573 23.7896 21.2368 25.3352 21.6547 26.6466C22.0727 27.9671 23.1109 29.0281 24.4636 29.8801C28.8983 32.6797 36.6585 33.2746 40.1976 32.1056C42.2852 31.4166 44.3108 30.5577 46.2813 29.5586Z"
        fill="white"
        fill-opacity="0.95"
      />
    </svg>
  );
}

export default SvgAddButton;
